import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Contact from "../components/Contact"

export const query = graphql`
  {
    allContentfulContactInfo {
      nodes {
        email
        numer
        adres
        godzinyTydzien
        godzinyWeekened
      }
    }
  }
`

export default function ContactPage({ data }) {
  const {
    allContentfulContactInfo: { nodes: contactData },
  } = data

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | Kontakt</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <Contact contactData={contactData} />
    </>
  )
}
