import React from "react"
import styled from "styled-components"

import telephoneFill from "../../assets/images/telephone-fill.svg"
import houseDoorFill from "../../assets/images/house-door-fill.svg"
import envelopeFill from "../../assets/images/envelope-fill.svg"

const ColumnLeft = styled.div`
  padding: 1rem 1rem 0.5rem 2rem;
  color: white;
  background-color: #e5007d;
  z-index: 15;

  @media (min-width: 992px) {
    display: none;
  }
`

const ColumnLeftAbsolute = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  padding: 1rem 1rem 0.5rem 2rem;
  color: white;
  /* background-color: #e5007d; */
  background-color: rgb(27, 27, 58);
  z-index: 15;

  @media (max-width: 992px) {
    display: none;
  }
`

const ColumnRight = styled.div`
  padding: 0;
  height: 60vh;
`

const Li = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
`

const A = styled.a`
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`

const Span = styled.span`
  word-wrap: break-word;
  margin-left: 0.5rem;
  letter-spacing: 1px;
  font-size: 14px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);

  .small {
    font-size: 12px;
  }
`

function Contact({ contactData }) {
  const { adres: address, email, numer: phoneNumber } = contactData[0]

  return (
    <div className="container mt-6 mb-5">
      <div className="row">
        <h1 className="page-header ml-auto mr-auto">Kontakt</h1>
      </div>
      <div className="row mt-5">
        <ColumnLeft className="col-12">
          <h3 className="mb-3">Dane kontaktowe</h3>
          <ul className="pl-0">
            <Li>
              <A href="tel:+48517410807">
                <img src={telephoneFill} alt="phone" />
                <Span>{phoneNumber}</Span>
              </A>
            </Li>
            <Li className="mt-3">
              <A href={`mailto:${email}`}>
                <img src={envelopeFill} alt="phone" />
                <Span>{email}</Span>
              </A>
            </Li>
            <Li className="mt-3">
              <img src={houseDoorFill} alt="building" />
              <Span>{address}</Span>
            </Li>
            <Li>
              <span className="small">
                *w KosmoEstetic, wejście od tyłu budynku
              </span>
            </Li>
          </ul>
        </ColumnLeft>
        <ColumnRight className="col-12 embed-responsive" id="map">
          <ColumnLeftAbsolute>
            <h4 className="mb-3">Dane kontaktowe</h4>
            <ul className="pl-0">
              <Li>
                <A href="tel:+48517410807">
                  <img src={telephoneFill} alt="phone" />
                  <Span>{phoneNumber}</Span>
                </A>
              </Li>
              <Li className="mt-3">
                <A href={`mailto:${email}`}>
                  <img src={envelopeFill} alt="phone" />
                  <Span>{email}</Span>
                </A>
              </Li>
              <Li className="mt-3">
                <img src={houseDoorFill} alt="building" />
                <Span>{address}</Span>
              </Li>
              <Li>
                <span className="small">
                  *w KosmoEstetic, wejście od tyłu budynku
                </span>
              </Li>
            </ul>
          </ColumnLeftAbsolute>
          <iframe
            className="embed-responsive-item"
            src={
              "https://maps.google.com/maps?q=Fizjowsulkowski+Gabinet+Fizjoterapii+i+Masażu+-+Wojciech+Sułkowski;t=&amp;z=15&ie=UTF8&iwloc=&output=embed"
            }
            width="100%"
            height="100%"
            style={{ border: "none" }}
            allow="encrypted-media"
            allowFullScreen
          ></iframe>
        </ColumnRight>
      </div>
    </div>
  )
}

export default Contact
